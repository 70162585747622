import { Link } from "@remix-run/react";
import parse, {
  DOMNode,
  Element,
  domToReact,
  HTMLReactParserOptions,
} from "html-react-parser";
import { useMemo } from "react";

export type HtmlRendererProps = {
  html: string;
};

const options: HTMLReactParserOptions = {
  replace(domNode) {
    if (domNode instanceof Element) {
      const { children: domChildren, tagName, attribs } = domNode;

      const children = domToReact(domChildren as DOMNode[], options);

      switch (tagName) {
        case "h2": {
          return <h2 className="my-8 text-3xl font-medium">{children}</h2>;
        }

        case "h3": {
          return <h3 className="my-6 text-xl font-medium">{children}</h3>;
        }

        case "p": {
          return <p className="my-4">{children}</p>;
        }

        case "ul": {
          return <ul className="pl-5 my-4">{children}</ul>;
        }

        case "li": {
          return <li className="list-disc">{children}</li>;
        }

        case "a": {
          const target = attribs.href.includes("zrazmotorkarov.sk")
            ? "_self"
            : "_blank";

          const to = attribs.href
            .replace("https://www.zrazmotorkarov.sk", "")
            .replace("http://www.zrazmotorkarov.sk", "")
            .replace("https://zrazmotorkarov.sk", "")
            .replace("http://zrazmotorkarov.sk", "")
            .replace("www.zrazmotorkarov.sk", "")
            .replace("zrazmotorkarov.sk", "");

          return (
            <Link
              className="underline"
              to={to}
              target={target}
              rel="noreferrer"
            >
              {children}
            </Link>
          );
        }

        case "strong": {
          return <strong className="font-medium">{children}</strong>;
        }

        case "i": {
          return <i className="italic">{children}</i>;
        }
      }
    }

    return null;
  },
};

export const HtmlRenderer = ({ html }: HtmlRendererProps) => {
  const parsedHtml = useMemo(() => {
    return parse(html, options);
  }, [html]);

  return <div>{parsedHtml}</div>;
};
